import React, { Component } from 'react'

class Playground extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {}
  render() {
    return (
      <div> 
          Hello world
      </div>
    )
  }
}
export default Playground
